<template>
  <div>
    <b-card no-body class="p-2">
      <b-row>
         <!-- teklif not form open -->
        <b-col cols="12" md="12" lg="12" class="ml-2">
          <b-form-group
            label-class="mb-1">
            <b-button
              size="sm"
              variant="primary"
              @click="isOpenNotSave = !isOpenNotSave">
              <feather-icon icon="ArrowDownIcon" class="mr-50" size="16" v-show="isOpenNotSave" />
              <feather-icon icon="ArrowUpIcon" class="mr-50" size="16" v-show="!isOpenNotSave" />
              <span class="align-middle">{{ $t('Yeni Teklif Notu Ekle') }}</span>
            </b-button>
          </b-form-group>
        </b-col>

        <!-- teklif not form -->
        <b-col cols="12" md="12" lg="12" v-show="isOpenNotSave">
          <b-card>
            <b-form-group>
              <label for="sAciklama">{{ $t('Açıklama') }}</label>
              <b-form-textarea
                id="sAciklama"
                :placeholder="$t('Açıklama')"
                v-model="teklifNotForm.sAciklama"
                rows="4"
              />
            </b-form-group>
            <b-form-group>
              <b-button
                variant="warning"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="teklifNotSave"
                :disabled="$store.getters.getLoader"
              >
              <b-spinner
                v-if="$store.getters.getLoader"
                class="mr-1"
                small
                variant="light"
              />
                <feather-icon v-if="!$store.getters.getLoader" icon="SaveIcon" class="mr-50" size="16" />
                {{ $t('Kaydet') }}
              </b-button>
            </b-form-group>
          </b-card>
        </b-col>

        <!-- teklif Not List -->
        <b-col cols="12" md="12" lg="12">
          <b-card>
            <b-card-title>{{ $t('Teklif Görüşmeleri') }}</b-card-title>
            <b-table
              class="position-relative"
              :items="teklifNotList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Kayıt bulunamadı"
            >
              <template #cell(dtOlusTarih)="data">
                {{ formatDateToDMYT(data.item.dtOlusTarih) }}
              </template>
              <template #cell(kullanici)="data">
                <div class="text-nowrap">
                  {{ data.item.notEkleyenKullanici.sAd + ' ' + data.item.notEkleyenKullanici.sSoyad }}
                  <b-badge
                    pill
                    variant="light-primary"
                    class="text-capitalize">
                    {{ resolveKullaniciTipi(data.item.notEkleyenKullanici.kullaniciTipi) }}
                  </b-badge>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { formatDateToDMYT } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions'
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  props: {
    teklifData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'dtOlusTarih', label: 'Tarih', sortable: true },
        { key: 'kullanici', label: 'Durum Ekleyen' },
        { key: 'sAciklama', label: 'Açıklama' },
      ],
      teklifNotList: [],
      isOpenNotSave: false,
      teklifNotForm: {
        pTeklifId: parseInt(this.teklifData.offer.id, 10),
        sepet_Id: null,
        sAciklama: null,
      },
    };
  },
  methods: {
    fetchTeklifNotList() {
      const queryParams = {
        teklifId: parseInt(this.teklifData.offer.id, 10),
      };

      this.$store.dispatch('teklifYonetim/fetchTeklifNots', queryParams)
            .then(response => {
              if (response.statusCode === 200) {
                this.teklifNotList = response.result;
              } else {
                AlertService.error(this, response.message)
              }
            })
    },

    formatDateToDMYT,

    resolveKullaniciTipi(role) {
      const kullaniciTipi = StaticOptions.kullaniciTipiOptions.find(value => value.value === role)
      if (kullaniciTipi) {
        return kullaniciTipi.label
      }

      return ''
    },

    teklifNotSave() {
      if (this.teklifNotForm.sAciklama === null || this.teklifNotForm.sAciklama === '') {
        AlertService.error(this, this.$i18n.t('Teklif Dosyasını Yükleyin.'));
        return;
      }
      this.$store.dispatch('teklifYonetim/saveTeklifNot', this.teklifNotForm)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.isOpenNotSave = false;
            this.teklifNotForm.sAciklama = null;
            this.fetchTeklifNotList();
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
  mounted() {
    this.fetchTeklifNotList();
  },
};
</script>
