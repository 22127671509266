<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-teklif-send-file"
      title="Teklif Dosya Gönderme"
      ok-title="Gönder"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      v-if="modalObject.item"
      @hidden="hiddenModal"
      @ok="handleOk"
    >
      <validation-observer ref="sendOfferFile">
        <b-form
          ref="form"
          @submit.stop.prevent="sendOfferFile">

          <b-form-group
            :label="$t('Gönderilecek Posta')">
            <validation-provider #default="{ errors }" rules="required" name="sEmail">
              <b-form-input
                id="sEmail"
                v-model="teklifSendRequest.sEmail"
                :placeholder="$t('E-Posta')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="E-Posta Başlık">
            <validation-provider #default="{ errors }" rules="required" name="sTitle">
              <b-form-input
                id="sTitle"
                v-model="teklifSendRequest.sTitle"
                :placeholder="$t('E-Posta Başlık')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="E-Posta İçerik">
            <validation-provider #default="{ errors }" rules="required" name="sIcerik">
              <b-form-textarea 
                id="sIcerik"
                v-model="teklifSendRequest.sIcerik" 
                :state="errors.length > 0 ? false:null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group v-show="isSending">
            <p class="text-success">
              E-posta gönderiliyor lütfen bekleyin...
            </p>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    modalObject: {
      type: Object,
       default() {
        return {}
      },
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      isSending: false,
      teklifSendRequest: {
        teklifId: this.modalObject.item.id,
        musteriKodu: this.modalObject.item.entityCode,
        sEmail: null,
        sTitle: null,
        sIcerik: null,
      },
    }
  },
  methods: {
    sendOfferFile() {
      this.$refs.sendOfferFile.validate().then(success => {
        if (success) {
          this.isSending = true;
          this.$store.dispatch('teklifYonetim/sendTeklifFile', this.teklifSendRequest, this)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
            } else {
             AlertService.error(this, response.message)
            }
            this.isSending = false;
          });
        }
      })
    },

    initialModal() {
      this.$refs['modal-teklif-send-file'].show();

      const queryParams = {
        pMusteriId: this.modalObject.item.entityId,
      };

      this.$store.dispatch('yonetimMusteri/fetchMusteriContactInfo', queryParams)
          .then(response => {
            if (response.statusCode === 200) {
              this.teklifSendRequest.sEmail = response.result.sMusteriEPosta;
              this.teklifSendRequest.sTitle = 'Teklif Dosyası';
              this.teklifSendRequest.sIcerik = `Sayın ${response.result.sMusteriAdi} teklif dosyası ekte iletilmiştir.`;
            } else {
              AlertService.error(this, response.message)
            }
          })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.sendOfferFile()
    },
    hiddenModal() {
      this.$refs['modal-teklif-send-file'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>
