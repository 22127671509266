<template>
  <div>
    <b-card no-body class="p-2">
      <b-row>
        <!-- teklif durum form open -->
        <b-col cols="12" md="12" lg="12" class="ml-2">
          <b-form-group label-class="mb-1">
            <b-button
              size="sm"
              variant="primary"
              @click="isOpenUpdateDurum = !isOpenUpdateDurum"
            >
              <feather-icon
                icon="ArrowDownIcon"
                class="mr-50"
                size="16"
                v-show="isOpenUpdateDurum"
              />
              <feather-icon
                icon="ArrowUpIcon"
                class="mr-50"
                size="16"
                v-show="!isOpenUpdateDurum"
              />
              <span class="align-middle">{{
                $t('Teklif Durumunu Değiştir')
              }}</span>
            </b-button>
          </b-form-group>
        </b-col>

        <!-- teklif durum form -->
        <b-col cols="12" md="12" lg="12" v-show="isOpenUpdateDurum">
          <b-card>
            <b-form-group>
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="teklifDurumForm.teklifDurumu"
                  name="some-radios"
                  value="0"
                >
                {{ $t('Teklifi bekleme durumuna al') }}
                </b-form-radio>
                <b-form-radio
                  v-model="teklifDurumForm.teklifDurumu"
                  name="some-radios"
                  value="3"
                >
                {{ $t('Teklifi reddet') }}
                </b-form-radio>
              </div>
            </b-form-group>
            <b-form-group :label="$t('Açıklama')" label-for="sAciklama">
              <b-form-textarea
                id="textarea-sAciklama"
                :placeholder="$t('Açıklama')"
                v-model="teklifDurumForm.teklifAciklama"
                rows="4"
              />
            </b-form-group>
            <b-form-group>
              <b-button
                variant="warning"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="teklifDurumGuncelle"
                :disabled="$store.getters.getLoader"
              >
                <b-spinner
                  v-if="$store.getters.getLoader"
                  class="mr-1"
                  small
                  variant="light"
                />
                <feather-icon
                  v-if="!$store.getters.getLoader"
                  icon="SaveIcon"
                  class="mr-50"
                  size="16"
                />
                {{ $t('Kaydet') }}
              </b-button>
            </b-form-group>
          </b-card>
        </b-col>

        <!-- teklif durum List -->
        <b-col cols="12" md="12" lg="12">
          <b-card>
            <b-card-title>{{ $t('Durum Geçmişi') }}</b-card-title>
            <b-table
              class="position-relative"
              :items="teklifDurumList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Kayıt bulunamadı"
            >
              <template #cell(dtCreated)="data">
                {{ formatDateToDMYT(data.item.dtCreated) }}
              </template>
               <template #cell(teklifEnum)="data">
                <b-badge :variant="data.item.teklifEnumVariant">{{ data.item.teklifEnum }}</b-badge>
              </template>
              <template #cell(islemYapanKullanici)="data">
                <div class="text-nowrap">
                  {{
                    data.item.islemYapanKullanici.sAd +
                    " " +
                    data.item.islemYapanKullanici.sSoyad
                  }}
                  <b-badge pill variant="light-primary" class="text-capitalize">
                    {{
                      resolveKullaniciTipi(
                        data.item.islemYapanKullanici.kullaniciTipi
                      )
                    }}
                  </b-badge>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { formatDateToDMYT } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions'
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  props: {
    teklifData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'dtCreated', label: 'Tarih', sortable: true },
        { key: 'islemYapanKullanici', label: 'Durum Ekleyen' },
        { key: 'teklifEnum', label: 'Durum' },
        { key: 'sAciklama', label: 'Açıklama' },
      ],
      teklifDurumList: [],
      isOpenUpdateDurum: false,
      teklifDurumForm: {
        teklifId: parseInt(this.teklifData.offer.id, 10),
        teklifAciklama: null,
        teklifDurumu: null,
      },
    };
  },
  methods: {
    fetchTeklifDurumList() {
      this.$store.dispatch('teklifYonetim/fetchTeklifDurumList', this.teklifData.sepetOffer.sepetId)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.map(this.resolveTeklifDurum);
            this.teklifDurumList = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    formatDateToDMYT,
    resolveKullaniciTipi(role) {
      const kullaniciTipi = StaticOptions.kullaniciTipiOptions.find(value => value.value === role)
      if (kullaniciTipi) {
        return kullaniciTipi.label
      }
      return ''
    },

    resolveTeklifDurum(item) {
      const teklifEnum = StaticOptions.teklifDurum.find(value => value.value === item.teklifDurumu)
      if (!teklifEnum) {
        item.teklifEnum = ''
      }
      item.teklifEnum = teklifEnum.label;
      item.teklifEnumVariant = this.resolveTeklifEnumVariant(item.teklifDurumu)
      return item;
    },
    resolveTeklifEnumVariant(durum) {
      const teklifDurum = {
        0: 'warning',
        1: 'success',
        2: 'info',
        3: 'danger',
      };
      return teklifDurum[durum] ? teklifDurum[durum] : 'primary';
    },

    teklifDurumGuncelle() {
      this.$store.dispatch('teklifYonetim/teklifDurumGuncelle', this.teklifDurumForm)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.$router.go();
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
  mounted() {
    this.fetchTeklifDurumList();
  },
};
</script>
