<template>
  <div>
    <b-card no-body class="p-2">
      <b-form>
        <b-row>
          <!-- Field: Bildiri -->
          <b-col cols="12" md="6" class="mt-2">
            <b-alert
              variant="danger"
              v-show="teklifData.sepetOffer.teklifDurumu === 0"
              show
            >
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Önemli') }}!</strong> 
                  {{ $t('Bu teklif daha müşteri tarafından onaylanmadı') }}.
                </span>
              </div>
            </b-alert>
            <b-alert
              variant="primary"
              v-show="teklifData.sepetOffer.teklifDurumu === 1"
              show
            >
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Önemli') }}!</strong> 
                  {{ $t('Teklif onaylandı. Şimdi teklife istinaden sipariş kaydı oluşturabilirsiniz') }}.
                </span>
              </div>
            </b-alert>
            <b-alert
              variant="success"
              v-show="teklifData.sepetOffer.teklifDurumu === 2"
              show
            >
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Önemli') }}!</strong>
                  {{ $t('Teklif onaylandı ve sipariş kaydı oluşturuldu') }}.
                </span>
              </div>
            </b-alert>
            <b-alert
              variant="danger"
              v-show="teklifData.sepetOffer.teklifDurumu === 3"
              show
            >
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Önemli') }}!</strong> 
                  {{ $t('Teklif reddedildi') }}.
                </span>
              </div>
            </b-alert>
          </b-col>

          <!-- Field: Dosyasını İndir -->
          <b-col cols="12" md="6" class="mb-3">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                :disabled="isDownloadTeklifChange"
                @click="openTeklifChangeUpload()"
              >
                <b-spinner small v-if="isDownloadTeklifChange" />
                <feather-icon
                  v-if="!isDownloadTeklifChange"
                  icon="EditIcon"
                  class="mr-50"
                  size="20"
                />
                {{ $t('Teklif Dosyasını Değiştir') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                :disabled="isDownload"
                @click="downloadTeklifFile()"
              >
                <b-spinner small v-if="isDownload" />
                <feather-icon
                  v-if="!isDownload"
                  icon="DownloadIcon"
                  class="mr-50"
                  size="20"
                />
                {{ $t('Teklif Dosyasını İndir') }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="sendTeklifFile()">
                <feather-icon
                  icon="SendIcon"
                  class="mr-50"
                  size="20"
                />
                {{ $t('Teklif Dosyasını Gönder') }}
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- Field: Kendim Olustur -->
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mb-3"
            v-if="teklifData.sepetOffer.teklifDurumu === 0"
          >
            <b-form-group label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="customTeklifOnayla"
                  class="custom-control-primary"
                >
                  {{ $t("Bu teklifi kendim onaylamak istiyorum") }}.
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Field: Teklif Siparis Olustur -->
        <b-row v-if="teklifData.sepetOffer.teklifDurumu === 1">
          <b-col cols="12" md="6">
            <b-button
              variant="warning"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="teklifSiparisOlustur()"
              :disabled="$store.getters.getLoader"
            >
            <b-spinner
                v-if="$store.getters.getLoader"
                class="mr-1"
                small
                variant="light"
              />
              <feather-icon v-if="!$store.getters.getLoader" icon="SaveIcon" class="mr-50" size="16" />
              {{ $t('Teklif Siparişi Oluştur') }}
            </b-button>
          </b-col>
        </b-row>

        <!-- Field: Dosyayı Yükle -->
        <b-row
          v-if="
            teklifData.sepetOffer.teklifDurumu === 0 &&
            customTeklifOnayla === true
          "
        >
          <b-col cols="12" md="6" class="mb-3">
            <b-form-group>
            <label for="docTraCode">{{ $t('Onaylanan Teklif Dosyasını Yükle') }}</label>
              <b-form-file
                v-model="onyRequest.file"
                id="file"
                ref="file-input"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Field: Teklif Onayla Btn -->
        <b-row
          v-if="
            teklifData.sepetOffer.teklifDurumu === 0 &&
            customTeklifOnayla === true
          "
        >
          <b-col cols="12" md="6">
            <b-button
              variant="warning"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="$store.getters.getLoader"
              @click="onayTeklif()"
            >
              <b-spinner
                v-if="$store.getters.getLoader"
                class="mr-1"
                small
                variant="light"
              />
              <feather-icon v-if="!$store.getters.getLoader" icon="SaveIcon" class="mr-50" size="16" />
              {{ $t('Teklifi Onayla') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <teklif-send-file-modal v-if="modalObject.showModal" :modalObject="modalObject" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';

// Alert
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import TeklifSendFileModal from '../teklif-modals/TeklifSendFileModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    TeklifSendFileModal,
  },
  props: {
    teklifData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      modalObject: {
        item: {},
        showModal: false,
      },

      isDownload: false,
      isDownloadTeklifChange: false,
      customTeklifOnayla: false,
      teklifId: parseInt(this.$route.params.id, 10),
      onyRequest: {
        file: null,
      },
    };
  },
  methods: {
    downloadTeklifFile() {
      const paramId = this.teklifId;
      const queryParams = {
        teklifId: paramId,
        musteriKodu: this.teklifData.offer.entityCode,
      };

      if (paramId) {
        this.isDownload = true;
        this.$store.dispatch('teklifYonetim/fetchTeklifFile', queryParams)
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response], { type: response.type }));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'teklif');
            document.body.appendChild(fileLink);

            fileLink.click();
            this.isDownload = false;
          })
          .catch(() => {
            this.isDownload = false
          })
      }
    },

    sendTeklifFile() {
      Object.assign(this.modalObject.item, this.teklifData.offer);
      this.modalObject.showModal = true;
    },

    onayTeklif() {
      if (this.onyRequest.file === null) {
        AlertService.error(this, this.$i18n.t('Teklif Dosyasını Yükleyin.'));
        return;
      }

      const onayForm = new FormData();
      onayForm.set('file', this.onyRequest.file);
      onayForm.set('teklifId', this.teklifId)
      this.$store.commit('setLoader', true);
      this.$store.dispatch('teklifYonetim/onayTeklif', onayForm)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.teklifData.sepetOffer = { teklifId: response.result.pId, teklifDurumu: response.result.teklifDurumu };
          } else {
            AlertService.error(this, response.message)
          }
          this.$store.commit('setLoader', false);
        })
    },

    teklifChangeFile(event) {
      const fileForm = new FormData();
      fileForm.set('file', event.target.files[0]);
      fileForm.set('pTeklifId', this.teklifData.offer.id);
      fileForm.set('sepetId', this.teklifData.sepetOffer.sepetId);
      this.$store.commit('setLoader', true);
      this.$store.dispatch('teklifYonetim/teklifChangeFile', fileForm)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.error(this, response.message)
          }
          this.$store.commit('setLoader', false);
        })
    },

    openTeklifChangeUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        this.teklifChangeFile(event);
      });

      fileInput.click();
    },

    teklifSiparisOlustur() {
      const paramId = this.teklifId;
      const queryParams = {
        teklifId: paramId,
      };
      this.$store.dispatch('teklifYonetim/teklifSiparisOlustur', queryParams)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.teklifData.sepetOffer = { teklifId: response.result.pId, teklifDurumu: response.result.teklifDurumu };
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },
};
</script>

<style scoped>
</style>
