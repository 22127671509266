<template>
  <div>
    <b-card no-body class="p-2">
      <b-form v-if="teklif">
        <b-row>
          <!-- Field: Müşteri Kodu -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label for="docNo">{{ $t('Belge No') }}</label>
              <b-form-input
                id="docNo"
                v-model="teklif.docNo"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Hareket Kodu -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label for="docTraCode">{{ $t('Hareket Kodu') }}</label>
              <b-form-input
                id="docTraCode"
                v-model="teklif.docTraCode"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Vergi No -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label for="entityName">{{ $t('Cari Adı') }}</label>
              <b-form-input
                id="entityName"
                v-model="teklif.entityName"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Teklif Durumu -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label for="offerStatus">{{ $t('Teklif Durumu') }}</label>
              <b-form-input
                id="offerStatus"
                v-model="teklif.offerStatus"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Alış/Satış -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label for="purchaseSales">{{ $t('Alış/Satış') }}</label>
              <b-form-input
                id="purchaseSales"
                v-model="teklif.purchaseSales"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Satış Personel -->
          <b-col cols="12" md="4">
            <b-form-group>
              <label for="salesPersonName">{{ $t('Satış Personel') }}</label>
              <b-form-input
                id="salesPersonName"
                v-model="teklif.salesPersonName"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <!-- Header: Teklif Detayi -->
      <div class="d-flex mt-3 mb-2">
        <h4 class="mb-0 ml-50 text-primary">
          {{ $t('Teklif Kalem Listesi') }}
        </h4>
      </div>

      <DxDataGrid
        :data-source="dataSource"
        :show-borders="true"
        :column-auto-width="true"
      >
        <DxColumn data-field="id" caption="Id" />
        <DxColumn data-field="docNo" caption="Belge No" />
        <DxColumn data-field="dcardCode" caption="Stok Kodu" />
        <DxColumn data-field="stockName" caption="Stok Adı" />
        <DxColumn data-field="unitCode" caption="Birim" />
        <DxColumn
          data-field="dxqty"
          caption="Miktarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="unitPriceTra"
          caption="Birim Fiyat"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="dxamtTra"
          caption="Hareket Tutarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn data-field="curTraCode" caption="Para Birimi" />
        <DxColumn
          data-field="curRateTra"
          caption="Kur"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="unitPrice"
          caption="Yerel Para Tutarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="dxamtWithDisc"
          caption="İskonto Tutarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="vatRate"
          caption="Kdv Yüzdesi"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxColumn
          data-field="dxamtVat"
          caption="Kdv Tutarı"
          alignment="right"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxSummary>
          <DxTotalItem
            column="dxqty"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="dxamtTra"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="dxamtWithDisc"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="dxamtVat"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
        </DxSummary>
      </DxDataGrid>
    </b-card>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import { locale } from 'devextreme/localization';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSummary, 
    DxTotalItem,
  },
  props: {
    teklifData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSource: this.teklifData.offer.uyumDetailItem,
      teklif: this.teklifData.offer,
      currentFilter: 'auto',
      dataGridRefName: 'dataGrid',
    };
  },
  methods: {
    customizeDecimalDxGrid,
  },
  created() {
    locale('tr')
  },
};
</script>

<style scoped>
#gridContainer {
  height: 440px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}
</style>
